<template>
  <div id="resetPassword">
    <vue-progress-bar class="vueProgressBar"></vue-progress-bar>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-12">
          <div class="login-logo mt-5">
            <a href="https://cvscreen.com.au/" target="blank"
              ><img
                src="../../assets/img/logo.png"
                class=""
                alt="CV Screen"
                style="height: 100px"
            /></a>
          </div>

          <img
            src="../../assets/img/sgi-registrer.png"
            class="img-fluid img-bottom"
            alt="CV Screen"
            width="500px"
          />
        </div>

        <div class="col-lg-5 col-md-5 col-12">
          <div class="login-page bg-body">
            <div class="login-box">
              <div class="card">
                <div class="card-body login-card-body">
                  <p class="login-box-msg">
                    You are only one step away from your new password, set and
                    confirm your password now.
                  </p>

                  <div
                    class="invalid-feedback mb-3"
                    v-if="errors.password_confirm"
                  >
                    {{ errors.password_confirm[0] }}
                  </div>
                  <div class="invalid-feedback mb-3" v-if="errors.password">
                    {{ errors.password[0] }}
                  </div>

                  <form autocomplete="off" @submit.prevent="resetPassword">
                    <div class="input-group mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        v-model="data.password"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    <div class="input-group mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Confirm Password"
                        v-model="data.password_confirm"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary btn-block">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>

                  <p class="mt-3 mb-1">
                    <router-link to="/login" class="small">Login</router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
export default {
  name: "Register",
  data() {
    return {
      errors: {},
      data: {
        token: null,
        password: null,
        password_confirm: null,
      },

      has_error: false,
    };
  },
  methods: {
    resetPassword: async function () {
      try {
        // Progress bar
        this.$Progress.start();

        this.data.token = this.$route.params.token;
        const response = await authService.resetPassword(this.data);

        // Progress bar
        this.$Progress.finish();

        this.$router.push({ name: "login" });
      } catch (error) {
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Password reset",
              error.response.data.message
            );
            break;
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Password reset",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Password reset",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Password reset",
              "Something wrong, Please try again!"
            );
            break;
        }

        console.log("resetPassword - Something Wrong", error);

        // Progress fail (error)
        this.$Progress.fail();
      }
    },
  },
};
</script>
<style scoped>
.login-box {
  width: 400px;
}
</style>
